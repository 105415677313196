export class JoinLoginModalImports {
  
  static async getDependencies() {
    // $UI object must exist for some of the sui dependencies
    window.$UI = window.$UI || {};
    // Gets all jQuery, JS, and CSS needed for join login modal
    await Promise.all([
      import('../sui-dependencies/init'),
    ]).then(async([{ initSuiDependencies }]) => await initSuiDependencies());
  }

  static async setupModal() {
    return import('../join-login/init');
  }
}