import { JoinLoginModalImports } from '@/join/components/dynamic-import-control/join-login-modal';
import "@/join/join_flow_styles/join-login.scss"
import "@/join/join_flow_styles/sui-styles.css"
import "../../assets/javascripts/join.js"

async function run() {
  await JoinLoginModalImports.getDependencies();
  await JoinLoginModalImports.setupModal();
}

run();


