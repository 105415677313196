/*******************************/
/* Join-And-Login Pseudo-Modal */
/*******************************/
/* The pseudo-modal box that includes the Join form _and_ Login form. Also includes the "mainModal" */
/* which is the instance of the "pseudo-modal" that actually pops up on most pages as a real modal  */
var JoinAndLogin = JoinAndLogin || {};
jQuery.extend(JoinAndLogin, {
  init: function($) {
    jQuery('body').on('click', 'a[data-social-join]', function() {
      // Need to open window immediately to avoid popup from being blocked
      var socialPopup = window.open('', 'sw_social_join', 'width=760,height=640'),
          social_network = jQuery(this).attr('data-social-join');

      socialPopup.document.write('Redirecting...');

      var $this = jQuery(this),
          form = $this.attr('data-join-form'),
          message = $this.attr('data-join-message'),
          referrer = window.location.pathname + window.location.search,
          loginRequestFromDiscourse = referrer.includes('sig=') && referrer.includes('sso='),
          identityUrl = '';

      if ( jQuery(this).attr('data-referrer') ){
        referrer = jQuery(this).attr('data-referrer');
      }

      var referrerFullUrl = window.origin + referrer;

      var windowUrl = identityUrl + '/auth/' + social_network + '?form=' + form + '&message=' + message;

      windowUrl += '&referer_full_url=' + encodeURIComponent(referrerFullUrl);
      windowUrl += '&login_request_from_discourse=' + encodeURIComponent(loginRequestFromDiscourse);

      socialPopup.location.href = windowUrl;
    });
  }
});

jQuery(function($) {
    JoinAndLogin.init($);
});